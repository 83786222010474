import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import styles from './styles'

const useStyles = makeStyles(styles)

const CardChart = ({ title, subTitle, children, emptyContent }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <Typography variant="body2" mb={1}>
        {title}
      </Typography>
      {subTitle && (
        <Box className={classes.subTitle}>
          <Typography variant="h2">
            {subTitle}
          </Typography>
        </Box>
      )}
      <CardContent className={classes.cardContent}>
        {emptyContent > 0 ? children : (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '15rem' }}
          >
            <Typography>
              There was not data found for this date range
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
  )
}

export default CardChart
