export default function styles(theme) {
  return {
    tab: {
      textTransform: 'none',
      color: theme.palette.secondary.main,
      fontWeight: 600,
    },
    upperSection: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '1rem',
      marginTop: '1rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: '11',
    },
    field: {
      maxWidth: '24.4rem',
    },
    boxDateRange: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    dateRangePicker: {
      border: `1px solid ${theme.color.lightGray}`,
      borderRadius: '4px',
    },
    spanDate: {
      marginLeft: '0.75rem',
    },
  }
}
