import { makeAutoObservable, flow } from 'mobx'

import * as srv from 'admin/services'
import handleError from 'admin/services/error-handler'

export default class ReportStore {
  loading = false
  processing = false
  list = []
  total = 0
  query = {
    name: '',
    sortBy: 'updatedAt',
    sortDirection: 'desc',
  }
  currentPage = 0
  rowsPerPage = 20

  constructor(rootStore) {
    makeAutoObservable(this, {
      fetch: flow,
      fetchTotalSales: flow,
    })
    this.rootStore = rootStore
  }

  updateQuery = (key, value, resetPage = false) => {
    this.query[key] = value
    if (resetPage) this.currentPage = 0
  }

  resetQuery = () => {
    this.query = {
      name: '',
    }
  }

  updatePage = (page) => {
    this.currentPage = page
  }

  updateRowsPerPage = (perPage) => {
    this.rowsPerPage = perPage
  }

  get queryParams() {
    const params = {
      sortBy: this.query.sortBy,
      sortDirection: this.query.sortDirection,
      limit: this.rowsPerPage,
      offset: this.currentPage * this.rowsPerPage,
    }
    if (this.query.name) params.name = this.query.name
    if (this.query.startDate) params.startDate = this.query.startDate
    if (this.query.endDate) params.endDate = this.query.endDate
    return params
  }

  * fetch(params) {
    try {
      this.loading = true
      const response = yield srv.fetchReportCustomer({
        ...this.queryParams,
        ...params,
      })
      if (response.ok) {
        this.list = response.data.list
        this.total = response.data.total
        return response.data
      }
      throw handleError(response)
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchTotalSales(params) {
    try {
      this.loading = true
      const response = yield srv.fetchTotalSales({
        ...this.queryParams,
        ...params,
      })
      if (!response.ok) throw handleError(response)
      return response.data
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }

  * fetchAverageOrder(params) {
    try {
      this.loading = true
      const response = yield srv.fetchAverageOrders({
        ...this.queryParams,
        ...params,
      })
      if (!response.ok) throw handleError(response)
      return response.data
    } catch (error) {
      return null
    } finally {
      this.loading = false
    }
  }
}
