import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'

import { useStores } from 'admin/hooks'
import { formatDateNameShortMonth } from 'admin/utils/helper'

import CardChart from 'admin/components/card-chart'
import Chart from 'admin/components/chart/chart-bar'

const SalesChart = ({ rangeDate }) => {
  const { reportStore, notificationStore } = useStores()

  const [sales, setSales] = useState([])
  const [totalSales, setTotalSales] = useState(0)

  useEffect(() => {
    const fetchSalesTotal = async () => {
      try {
        const response = await reportStore.fetchTotalSales({
          startDate: rangeDate.startDate,
          endDate: rangeDate.endDate,
        })
        setSales(response.totalPerDate)
        setTotalSales(Number(response.totalSales))
      } catch (error) {
        notificationStore.setError(error)
      }
    }
    fetchSalesTotal()
  }, [rangeDate])

  const widthBar = useMemo(() => {
    let width = 0.9
    if (sales.length === 1) {
      width = 0.2
    } else if (sales.length === 2) {
      width = 0.4
    } else if (sales.length === 3) {
      width = 0.5
    }
    return width
  }, [sales])

  const data = useMemo(() => {
    if (!sales) {
      return {}
    }

    return {
      labels: sales.map((r) => moment(r.date).format('MM/DD')),
      datasets: [
        {
          label: 'Total sales',
          backgroundColor: 'rgba(41,182,246,1)',
          data: sales.map((r) => r.totalAmount),
          afterTitle: sales.map((r) => formatDateNameShortMonth(r.date)),
          beforeBody: sales.map((r) => `Total sale = SGD${r.totalAmount}`),
          borderRadius: 4,
          barPercentage: widthBar,
        },
      ],
    }
  }, [sales])

  return (
    <CardChart
      title="Total sales"
      subTitle={`$${totalSales.toFixed(2)}`}
      emptyContent={totalSales}
    >
      <Chart
        data={data}
        hideInfoColorGraph
        customTooltip
      />
    </CardChart>
  )
}

export default observer(SalesChart)
