import React, { useState, useCallback } from 'react'
import { computed } from 'mobx'
import { observer } from 'mobx-react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import DateRangeIcon from '@mui/icons-material/DateRange'

import DateRangePicker from 'admin/components/date-range-picker'
import { formatDate, aWeekAgo } from 'admin/utils/helper'

import TotalSalesChart from './chart-customer/total-sales'
import AverageOrder from './chart-customer/average-order'

import styles from '../styles'

const useStyles = makeStyles(styles)

const AnalyticsCustomer = () => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [rangeDate, setRangeDate] = useState({
    key: 'selection',
    startDate: aWeekAgo,
    endDate: new Date(),
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = useCallback(values => {
    const { startDate, endDate } = values.selection
    setRangeDate(prev => ({
      ...prev,
      startDate,
      endDate,
    }))
  }, [])

  const date = computed(() => {
    const { startDate, endDate } = rangeDate
    return `${formatDate(startDate)} - ${formatDate(endDate)}`
  }).get()

  return (
    <Box>
      <Box className={classes.boxDateRange}>
        <Box className={classes.dateRangePicker}>
          <span className={classes.spanDate}>
            {date}
          </span>
          <IconButton onClick={handleClick} size="small">
            <DateRangeIcon />
          </IconButton>
        </Box>
        <DateRangePicker
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleSelect={handleSelect}
          selectionRange={rangeDate}
        />
      </Box>
      <Grid
        container
        spacing={2}
        mt={1}
      >
        <Grid
          item
          xs={4}
        >
          <TotalSalesChart rangeDate={rangeDate} />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <TotalSalesChart rangeDate={rangeDate} />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <AverageOrder rangeDate={rangeDate} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default observer(AnalyticsCustomer)
