import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ChangePassword from './pages/change-password'
import ForgetPassword from './pages/forget-password'
import Login from './pages/login'

import Admin from './pages/admin'
import AppCharge from './pages/app-charge'
import Booking from './pages/booking'
import BookingList from './pages/booking-list'
import BookingLog from './pages/booking-list/info/action-log'
import Customer from './pages/customer'
import Dashboard from './pages/dashboard'
import Driver from './pages/driver'
import Setting from './pages/setting'
import Invoice from './pages/invoice'
import Profile from './pages/profile'
import POS from './pages/pos'
import Payment from './pages/payment'
import Defect from './pages/defect'
import SmsLog from './pages/sms-log'
import Wallet from './pages/wallet'
import ShortLink from './pages/short-link'
import ManualCharge from './pages/manual-charge'
import Report from './pages/report'

export const RoutesNotLoggedIn = () => (
  <Switch>
    <Route path="/forget-password" component={ForgetPassword} />
    <Route path="/reset-password" component={ChangePassword} />
    <Route component={Login} />
  </Switch>
)

export const RoutesCounterStaff = () => (
  <Switch>
    <Route path="/booking/:id?" component={Booking} />
    <Route path="/booking-list" component={BookingList} />
    <Route component={BookingList} />
  </Switch>
)

export const RoutesLoggedIn = () => (
  <Switch>
    <Route path="/payment" component={Payment} />
    <Route path="/invoice-payment" component={Payment} />
    <Route path="/setting" component={Setting} />
    <Route path="/invoice" component={Invoice} />
    <Route path="/admin" component={Admin} />
    <Route path="/app-charge" component={AppCharge} />
    <Route path="/driver" component={Driver} />
    <Route path="/defect" component={Defect} />
    <Route path="/customer" component={Customer} />
    <Route path="/booking/:id?" component={Booking} />
    <Route path="/booking-list" component={BookingList} />
    <Route path="/booking-log/:id?" component={BookingLog} />
    <Route path="/profile" component={Profile} />
    <Route path="/pos" component={POS} />
    <Route path="/sms-log" component={SmsLog} />
    <Route path="/wallet" component={Wallet} />
    <Route path="/short-link" component={ShortLink} />
    <Route path="/manual-charge" component={ManualCharge} />
    <Route path="/report" component={Report} />
    <Route component={Dashboard} />
  </Switch>
)
