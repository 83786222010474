import React, { useCallback } from 'react'
import { observer } from 'mobx-react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Button,
} from '@mui/material'
import { WhatsApp as WhatsAppIcon, Cached as CachedIcon } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { useStores } from 'admin/hooks'

import { BOOKING_STATUS } from 'admin/config'

import { getTags, formatDate, formatTimeOnly } from 'admin/utils/helper'

import ServiceIcon from 'admin/components/service-icon'
import Status from 'admin/components/status'
import DriverType from 'admin/components/driver-type'

import styles from './styles'

const useStyles = makeStyles(styles)

const PHONE_CODE = 65
const rescheduleText =
  '*Rescheduling your Pickup*\nHi there, we are so sorry that our driver was unable to fulfil your pickup request yesterday. We will like to reschedule your pick up, please let us know your next availability. Thank you'

const getWhatsAppLink = (option) => {
  const messageText = encodeURIComponent(rescheduleText)
  return `https://api.whatsapp.com/send/?phone=${option.phoneNumber}&text=${messageText}`
}

const CELL_NAME = ['Date & time', 'Customers', 'Services', 'Drivers', 'Status']

const Action = ({ row }) => {
  const { delivery } = getTags(row.bookingTags)

  const { bookingStore } = useStores()

  const handleShowRebook = () => {
    bookingStore.setSelected(row)
    bookingStore.setRebookDialog(true)
  }

  const sendWhatsApp = useCallback(() => {
    const custumerData = {
      phoneNumber: `${PHONE_CODE}${row?.customer?.phoneNumber}`,
    }
    const url = getWhatsAppLink(custumerData)
    window.open(url, '_blank')
  }, [row?.customer?.phoneNumber])

  return (
    <>
      {delivery.length === 0 && (
        <Button
          variant="text"
          color="whatsapp"
          startIcon={<WhatsAppIcon />}
          onClick={sendWhatsApp}
        >
          Reschedule
        </Button>
      )}
      <Button
        variant="text"
        color="error"
        startIcon={<CachedIcon />}
        onClick={handleShowRebook}
      >
        Rebook
      </Button>
    </>
  )
}

const BasicTable = ({ list, tabFailed }) => {
  const classes = useStyles()

  const cellTable = tabFailed ? [...CELL_NAME, 'Action'] : CELL_NAME

  const renderService = (bookingTags) => {
    const { pickup, delivery } = getTags(bookingTags)

    return (
      <Stack
        direction="column"
        className={classes.tableIcon}
      >
        {pickup.length > 0 && (
          <ServiceIcon
            iconName="pickup"
            data={pickup}
          />
        )}
        {delivery.length > 0 && (
          <ServiceIcon
            iconName="delivery"
            data={delivery}
          />
        )}
      </Stack>
    )
  }

  return (
    <TableContainer
      classes={{
        root: classes.container,
      }}
    >
      {list.length ? (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {cellTable.map((name) => {
                return (
                  <TableCell
                    key={name}
                    className={classes.cellHeader}
                  >
                    {name}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Stack direction="column">
                    <Typography variant="h5">{formatDate(row.date)}</Typography>
                    <Typography variant="h5">{formatTimeOnly(row.date)}</Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack direction="column">
                    <Typography variant="h5">
                      {row.customer?.name || row.customer?.originalData?.name}
                    </Typography>
                    <Typography variant="h5">
                      {row.customer?.phoneNumber || row.customer?.originalData?.mobileNo}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>{renderService(row.bookingTags)}</TableCell>
                <TableCell>
                  {row.driver && (
                    <DriverType
                      driver={row.driver}
                      label={row.driver?.code}
                      labelVariant="h5"
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Status
                    variant="h5"
                    status={row.status}
                    text
                    reasonFailed={row.status === BOOKING_STATUS.FAILED && row.reason}
                  />
                </TableCell>
                {tabFailed && (
                  <TableCell>
                    <Action row={row} />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.text}
        >
          <Typography>No Rows</Typography>
        </Stack>
      )}
    </TableContainer>
  )
}

export default observer(BasicTable)
