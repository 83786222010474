import React, { memo } from 'react'
import PropTypes from 'prop-types'

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Tooltip,
  Legend,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  BarElement,
  Tooltip,
  Legend,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
)

const formatUSD = (value) => {
  return `SGD${value}`
}

const customTooltips = {
  displayColors: false,
  yAlign: 'bottom',
  callbacks: {
    title: () => {
      return ''
    },
    label: () => {
      return ''
    },
    afterTitle: (context) => {
      return `${context[0].dataset.afterTitle[context[0].dataIndex]}`
    },
    beforeBody: (context) => {
      return `${context[0].dataset.beforeBody[context[0].dataIndex]}`
    },
  },
}

// https://react-chartjs-2.js.org/examples/vertical-bar-chart
const Chart = (props) => {
  const {
    data,
    customTooltip,
  } = props

  return (
    <Bar
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          titleFontFamily: 'Open Sans',
          bodyFontFamily: 'Open Sans',
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: customTooltip ? customTooltips : {},
        },
        scales: {
          y: {
            beginAtZero: true,
            suggestedMin: 5,
            suggestedMax: 10,
            ticks: {
              callback: (value) => {
                return formatUSD(value)
              },
            },
          },
        },
      }}
    />
  )
}

Chart.propTypes = {
  data: PropTypes.object,
}

Chart.defaultProps = {
  data: {},
}
export default memo(Chart)
