import React, { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'

import { useStores } from 'admin/hooks'
import { formatDateNameShortMonth } from 'admin/utils/helper'

import CardChart from 'admin/components/card-chart'
import Chart from 'admin/components/chart/chart-bar'

const AverageOrderChart = ({ rangeDate }) => {
  const { reportStore, notificationStore } = useStores()

  const [averageOrders, setAverageOrders] = useState([])
  const [totalAverage, setTotalAverage] = useState(0)

  useEffect(() => {
    const fetchAverage = async () => {
      try {
        const response = await reportStore.fetchAverageOrder({
          startDate: rangeDate.startDate,
          endDate: rangeDate.endDate,
        })
        setAverageOrders(response.averagePerDate)
        setTotalAverage(Number(response.totalAverage))
      } catch (error) {
        notificationStore.setError(error)
      }
    }
    fetchAverage()
  }, [rangeDate])

  const widthBar = useMemo(() => {
    let width = 0.9
    if (averageOrders.length === 1) {
      width = 0.2
    } else if (averageOrders.length === 2) {
      width = 0.4
    } else if (averageOrders.length === 3) {
      width = 0.5
    }
    return width
  }, [averageOrders])

  const data = useMemo(() => {
    if (!averageOrders) {
      return {}
    }

    return {
      labels: averageOrders.map((r) => moment(r.date).format('MM/DD')),
      datasets: [
        {
          label: 'Average order',
          backgroundColor: 'rgba(41,182,246,1)',
          data: averageOrders.map((r) => r.averageByDate),
          afterTitle: averageOrders.map((r) => formatDateNameShortMonth(r.date)),
          beforeBody: averageOrders.map((r) => `Average order = SGD${r.averageByDate}`),
          borderRadius: 4,
          barPercentage: widthBar,
        },
      ],
    }
  }, [averageOrders])

  return (
    <CardChart
      title="Average order value"
      subTitle={`$${totalAverage.toFixed(2)}`}
      emptyContent={totalAverage}
    >
      <Chart
        data={data}
        hideInfoColorGraph
        customTooltip
      />
    </CardChart>
  )
}

export default observer(AverageOrderChart)
