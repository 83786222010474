import ActionLogStore from './action-log'
import AdminStore from './admin'
import PaginationStore from './pagination'
import NotificationStore from './notification'
import DriverStore from './driver'
import InfoSectionStore from './info-section'
import HolidayStore from './holiday'
import BookingTagStore from './booking-tag'
import SlotStore from './slot'
import ChannelStore from './channel'
import Keyboard from './keyboard'
import CustomerStore from './customer'
import BookingStore from './booking'
import InvoiceStore from './invoice'
import DriverHolidayStore from './driver-holiday'
import RoleStore from './role'
import ProfileStore from './profile'
import POSStore from './pos'
import InvoicePaymentStore from './invoice-payment'
import BookingPaymentStore from './booking-payment'
import InvoiceItemStore from './invoice-item'
import PriceCategoryStore from './price-category'
import WalletStore from './wallet'
import BranchStore from './branch'
import ShortLinkStore from './short-link'
import ManualChargeStore from './manual-charge'
import ReportStore from './report'

class RootStore {
  constructor() {
    this.actionLogStore = new ActionLogStore(this)
    this.adminStore = new AdminStore(this)
    this.paginationStore = new PaginationStore(this)
    this.notificationStore = new NotificationStore(this)
    this.driverStore = new DriverStore(this)
    this.infoSectionStore = new InfoSectionStore(this)
    this.holidayStore = new HolidayStore(this)
    this.bookingTagStore = new BookingTagStore(this)
    this.slotStore = new SlotStore(this)
    this.channelStore = new ChannelStore(this)
    this.keyboardStore = new Keyboard(this)
    this.customerStore = new CustomerStore(this)
    this.bookingStore = new BookingStore(this)
    this.invoiceStore = new InvoiceStore(this)
    this.driverHolidayStore = new DriverHolidayStore(this)
    this.roleStore = new RoleStore(this)
    this.profileStore = new ProfileStore(this)
    this.posStore = new POSStore(this)
    this.invoicePaymentStore = new InvoicePaymentStore(this)
    this.bookingPaymentStore = new BookingPaymentStore(this)
    this.invoiceItemStore = new InvoiceItemStore(this)
    this.priceCategoryStore = new PriceCategoryStore(this)
    this.walletStore = new WalletStore(this)
    this.branchStore = new BranchStore(this)
    this.shortLinkStore = new ShortLinkStore(this)
    this.manualChargeStore = new ManualChargeStore(this)
    this.reportStore = new ReportStore(this)
  }
}

export const rootStore = new RootStore()
