import React, { useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'

import { useStores } from 'admin/hooks'
import CustomDataGrid from 'admin/components/data-grid'

const columns = [
  {
    field: 'name',
    headerName: 'Customer Name',
    minWidth: 300,
    sortable: true,
  },
  {
    field: 'email',
    headerName: 'Email Subscription',
    minWidth: 250,
    sortable: true,
  },
  {
    field: 'address',
    headerName: 'Location',
    minWidth: 350,
    flex: 1,
    sortable: true,
  },
  {
    field: 'totalOrders',
    headerName: 'Orders',
    minWidth: 200,
    sortable: false,
    renderCell: (params) => `${params.row.totalOrders} Orders`,
  },
  {
    field: 'totalAmount',
    headerName: 'Amount spent',
    minWidth: 200,
    sortable: false,
    renderCell: (params) => `$${Number(params.row.totalAmount).toFixed(2)}`,
  },
]

const ReportDataGrid = () => {
  const { reportStore, infoSectionStore } = useStores()

  const handlePageChange = useCallback((params) => {
    reportStore.updatePage(params)
  }, [])

  const handlePageSizeChange = useCallback((param) => {
    reportStore.updateRowsPerPage(param)
  }, [])

  const handleRowClick = useCallback((rowData) => {
    infoSectionStore.select(rowData.row)
  }, [])

  const onSortModelChange = (param) => {
    if (param.length === 1) {
      reportStore.updateQuery('sortBy', param[0].field, true)
      reportStore.updateQuery('sortDirection', param[0].sort, true)
      return
    }
    reportStore.resetQuery()
  }

  useEffect(() => {
    reportStore.fetch()
  }, [reportStore.queryParams])

  return (
    <CustomDataGrid
      rows={reportStore.list}
      columns={columns}
      handleRowClick={handleRowClick}
      handlePageChange={handlePageChange}
      rowCount={reportStore.total}
      pageSize={reportStore.rowsPerPage}
      page={reportStore.currentPage}
      handlePageSizeChange={handlePageSizeChange}
      onSortModelChange={onSortModelChange}
      loading={reportStore.loading}
      rowsPerPageOptions={[20, 50, 100]}
    />
  )
}

export default observer(ReportDataGrid)
