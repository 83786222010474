import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import moment from 'moment'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Switch,
  Stack,
  FormControl,
  FormLabel,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateRange,
} from '@mui/icons-material/'

import DateRangePicker from 'admin/components/date-range-picker'

import { useStores, useRequest } from 'admin/hooks'
import * as srv from 'admin/services'

import CustomizedAutocomplete from 'admin/components/autocomplete'
import CustomizedDialogTitle from 'admin/components/dialog-title'
import TextField from 'admin/components/textfield'
import { dateOnlyFormat, formatDate, replaceToNumbers } from 'admin/utils/helper'
import styles from '../styles'

const useStyles = makeStyles(styles)

const USER_TYPES = {
  admin: 'Admin',
  driver: 'Driver',
  customer: 'Customer',
}

const REVIEW_STATUS = {
  reviewed: 'reviewed',
  unreviewed: 'unreviewed',
}

const DateFilter = ({
  checked,
  onChange,
  name,
  label,
  handleSelect,
  selectionRange,
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseCalendar = () => {
    setAnchorEl(null)
  }

  const date = computed(() => {
    const { startDate, endDate } = selectionRange
    if (!startDate) return '-'
    if (formatDate(startDate) !== formatDate(endDate)) {
      return `${formatDate(startDate)} - ${formatDate(endDate)}`
    }
    return formatDate(startDate)
  }).get()

  return (
    <>
      <Typography variant="h4">
        {label}
      </Typography>
      <div className={classes.dateOption}>
        <Switch
          checked={checked}
          onChange={onChange}
          name={name}
        />
        <div className={classes.datePicker}>
          <span>
            {date}
          </span>
          <IconButton
            onClick={handleClick}
            size="large"
          >
            <DateRange />
          </IconButton>
        </div>
        <DateRangePicker
          anchorEl={anchorEl}
          handleClose={handleCloseCalendar}
          handleSelect={handleSelect}
          selectionRange={selectionRange}
          onDialog
        />
      </div>
    </>
  )
}

const Filter = () => {
  const classes = useStyles()
  const {
    bookingStore,
  } = useStores()

  const [bookingCode, setBookingCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [address, setAddress] = useState('')
  const [status, setStatus] = useState('')
  const [driverOptions, setDriverOptions] = useState([])
  const [drivers, setDrivers] = useState(null)
  const [channelOptions, setChannelOptions] = useState([])
  const [showDelete, setShowDelete] = useState(false)
  const [invoicePaymentMethod, setInvoicePaymentMethod] = useState('')

  const [userType, setUserType] = useState('')
  const [reviewStatus, setReviewStatus] = useState('')
  const [createdBy, setCreatedBy] = useState(null) // initial state must be null
  const [createdByOptions, setCreatedByOptions] = useState([])
  const [valueInputCreatedBy, setValueInputCreatedBy] = useState('')

  const [isChecked, setisChecked] = useState({
    date: false,
    createdAt: true,
  })

  const yesterdayCreatedAt = new Date()
  yesterdayCreatedAt.setDate(yesterdayCreatedAt.getDate() - 1)
  const [rangeCreatedAt, setRangeCreatedAt] = useState({
    key: 'selection',
    startDate: yesterdayCreatedAt,
    endDate: new Date(),
  })

  const [rangeDate, setRangeDate] = useState({
    key: 'selection',
    startDate: null,
    endDate: new Date(''),
  })

  const handleSelectRangeDate = useCallback(values => {
    const { startDate, endDate } = values.selection
    setRangeDate(prev => ({
      ...prev,
      startDate,
      endDate,
    }))
    setisChecked(prev => ({
      ...prev,
      date: true,
    }))
  }, [])

  const handleSelectRangeCreatedAt = useCallback(values => {
    const { startDate, endDate } = values.selection
    setRangeCreatedAt(prev => ({
      ...prev,
      startDate,
      endDate,
    }))
    setisChecked(prev => ({
      ...prev,
      createdAt: true,
    }))
  }, [])

  const handleChangeSwitch = (event) => {
    setisChecked(prev => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }))
    if (
      event.target.name === 'date' &&
      !event.target.checked
    ) {
      setRangeDate(prev => ({
        ...prev,
        startDate: null,
        endDate: new Date(''),
      }))
    } else if (
      event.target.name === 'createdAt' &&
      !event.target.checked
    ) {
      setRangeCreatedAt(prev => ({
        ...prev,
        startDate: null,
        endDate: new Date(''),
      }))
    }
  }

  const {
    request: fetchDriver,
    loading: driverLoading,
  } = useRequest(srv.fetchDriver, {
    concurrent: true,
    initialData: { list: [], total: 0 },
    transformData: (data) => {
      if (data && data.list.length > 0) {
        setDriverOptions(data.list.map(item => ({
          key: item.id,
          label: item.code,
        })))
        if (userType) {
          setCreatedByOptions(data.list.map(item => ({
            key: item.id,
            label: item.name,
          })))
        }
      }
    },
  })

  const {
    request: fetchAdminName,
    loading: adminNameLoading,
  } = useRequest(srv.fetchAdminName, {
    concurrent: true,
    initialData: { list: [], total: 0 },
    transformData: (data) => {
      if (data && data.list.length > 0) {
        setCreatedByOptions(data.list.map(item => ({
          key: item.id,
          label: item.name,
        })))
      }
    },
  })

  const {
    request: fetchCustomer,
    loading: customerLoading,
  } = useRequest(srv.fetchCustomer, {
    concurrent: true,
    initialData: { list: [], total: 0 },
    transformData: (data) => {
      if (data && data.list.length > 0) {
        if (userType) {
          setCreatedByOptions(data.list.map(item => ({
            key: item.id,
            label: item.name,
          })))
        }
      }
    },
  })

  const {
    request: fetchChannel,
  } = useRequest(srv.fetchChannel, {
    concurrent: true,
    initialData: { list: [], total: 0 },
    transformData: (data) => {
      if (data && data.list.length > 0) {
        const transformChannel = data.list.map(item => {
          if (item.name === 'System') {
            return {
              key: item.id,
              label: item.name,
              isChecked: false,
            }
          }
          return {
            key: item.id,
            label: item.name,
            isChecked: true,
          }
        })
        setChannelOptions(transformChannel)
      }
    },
  })

  useEffect(() => {
    fetchChannel()
  }, [])

  const handleChangeCheckbox = (event) => {
    const idS = channelOptions.map((object) => {
      if (object.label === event.target.name) {
        return {
          ...object,
          isChecked: event.target.checked,
        }
      }
      return object
    })
    setChannelOptions(idS)
  }

  const handleOpenSearchDriver = useCallback(() => {
    fetchDriver({ status: 'active' })
  }, [])

  const handleSearchDriverInputChange = useCallback((event, newValue) => {
    if (!newValue) {
      setDriverOptions([])
      return
    }
    fetchDriver({
      code: newValue,
      status: 'active',
    })
  }, [])

  const handleChangeSearchDriver = useCallback((_, newValue) => {
    setDrivers(newValue)
  }, [])

  const handleClose = useCallback(() => {
    bookingStore.setOpenFilterDialog(false)
  }, [])

  const handleReset = useCallback(() => {
    setBookingCode('')
    setPhoneNumber('')
    setAddress('')
    setDrivers(null)
    setStatus('')
    setInvoicePaymentMethod('')
    setShowDelete(false)
    setisChecked({
      date: false,
      createdAt: false,
    })
    setRangeDate(prev => ({
      ...prev,
      startDate: null,
      endDate: new Date(''),
    }))
    setRangeCreatedAt(prev => ({
      ...prev,
      startDate: null,
      endDate: new Date(''),
    }))
    setChannelOptions(prev => {
      return prev.map(prevChannel => ({
        ...prevChannel,
        isChecked: false,
      }))
    })
    setUserType('')
    setReviewStatus('')
    setCreatedBy(null)
    setCreatedByOptions([])
  }, [])

  const handleOpenSearchCreatedBy = useCallback(() => {
    if (userType === USER_TYPES.admin) fetchAdminName()
    if (userType === USER_TYPES.driver) fetchDriver()
    if (userType === USER_TYPES.customer) fetchCustomer({ limit: 5 })
  }, [userType])

  const handleChangeSearchCreatedBy = useCallback((_, newValue) => {
    setCreatedBy(newValue)
  }, [])

  const handleCreatedByInputChange = useCallback((event, newValue) => {
    setValueInputCreatedBy(newValue)
  }, [])

  const handleChangeUserType = useCallback((event) => {
    setUserType(event.target.value)
    setCreatedBy(null)
    setCreatedByOptions([])
  }, [])

  const handleSubmit = useCallback(() => {
    const isDateChecked = isChecked.date
    const isCreatedAtChecked = isChecked.createdAt
    const startCreatedAt = moment(rangeCreatedAt.startDate).startOf('day').format()
    const endCreatedAt = moment(rangeCreatedAt.endDate).endOf('day').format()

    bookingStore.updateQuery('startDate', isDateChecked ?
      dateOnlyFormat(rangeDate.startDate) : null, true)
    bookingStore.updateQuery('endDate', isDateChecked ?
      dateOnlyFormat(rangeDate.endDate) : null, true)

    bookingStore.updateQuery('startCreatedAt', isCreatedAtChecked ?
      startCreatedAt : null, true)
    bookingStore.updateQuery('endCreatedAt', isCreatedAtChecked ?
      endCreatedAt : null, true)

    bookingStore.updateQuery('code', bookingCode.trim(), true)
    bookingStore.updateQuery('phoneNumber', phoneNumber.trim(), true)
    bookingStore.updateQuery('address', address.trim(), true)
    bookingStore.updateQuery('driverId', drivers?.key, true)
    bookingStore.updateQuery('status', status, true)
    bookingStore.updateQuery('showDelete', showDelete, true)
    bookingStore.updateQuery('invoicePaymentMethod', invoicePaymentMethod, true)
    bookingStore.updateQuery('reviewStatus', reviewStatus, true)

    const createdByQuery = createdBy ? `${userType} ${createdBy.label}` : userType
    bookingStore.updateQuery('createdBy', createdByQuery || null, true)

    const checkedChannelIds = channelOptions
      .filter(channel => channel.isChecked === true)
      .map(item => item.key)

    bookingStore.updateQuery('channelIds', checkedChannelIds || null, true)

    bookingStore.setDefaultFilter(false)

    bookingStore.setOpenFilterDialog(false)
  }, [
    phoneNumber,
    address,
    drivers,
    status,
    rangeCreatedAt,
    rangeDate,
    channelOptions,
    showDelete,
    userType,
    createdBy,
    invoicePaymentMethod,
    reviewStatus,
    bookingCode,
  ])

  useEffect(() => {
    if (valueInputCreatedBy &&
      userType === USER_TYPES.customer
    ) {
      fetchCustomer({
        name: valueInputCreatedBy,
        limit: 5,
      })
    }
  }, [valueInputCreatedBy])

  if (!bookingStore.dialog.filter) return null

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={bookingStore.dialog.filter}
      onClose={handleClose}
      aria-labelledby="Filter"
    >
      <CustomizedDialogTitle onReset={handleReset}>
        Booking Filter
      </CustomizedDialogTitle>
      <DialogContent>
        <DateFilter
          checked={isChecked.date}
          onChange={handleChangeSwitch}
          name="date"
          label="Booking Date"
          handleSelect={handleSelectRangeDate}
          selectionRange={rangeDate}
        />
        <DateFilter
          checked={isChecked.createdAt}
          onChange={handleChangeSwitch}
          name="createdAt"
          label="Date created"
          handleSelect={handleSelectRangeCreatedAt}
          selectionRange={rangeCreatedAt}
        />
        <FormControlLabel
          className={classes.showDelete}
          control={(
            <Switch
              checked={showDelete}
              onChange={(event) => setShowDelete(event.target.checked)}
            />
          )}
          label="Show Deleted Booking"
        />
        <TextField
          name="code"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          label="Booking Code"
          value={bookingCode}
          onChange={(e) => {
            setBookingCode(e.target.value)
          }}
          className={classes.textField}
        />
        <TextField
          name="phoneNumber"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          label="Phone No."
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(replaceToNumbers(e.target.value))
          }}
          className={classes.textField}
        />
        <CustomizedAutocomplete
          label="Driver"
          size="small"
          value={drivers}
          handleOpen={handleOpenSearchDriver}
          handleInputChange={handleSearchDriverInputChange}
          handleChange={handleChangeSearchDriver}
          options={driverOptions}
          loading={driverLoading}
          className={classes.autocomplete}
        />
        <TextField
          name="address"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          label="Address"
          value={address}
          onChange={(e) => {
            setAddress(e.target.value)
          }}
          className={classes.textField}
        />
        <TextField
          name="status"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          select
          label="Status"
          value={status}
          onChange={(e) => {
            setStatus(e.target.value)
          }}
          className={classes.textField}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="scheduled">Scheduled</MenuItem>
          <MenuItem value="on the way">On the way</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
          <MenuItem value="failed">Failed</MenuItem>
        </TextField>
        <TextField
          name="invoicePaymentMethod"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          select
          label="Invoice Payment Method"
          value={invoicePaymentMethod}
          onChange={(e) => {
            setInvoicePaymentMethod(e.target.value)
          }}
          className={classes.textField}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="APP">Stripe</MenuItem>
        </TextField>
        <TextField
          name="reviewStatus"
          type="text"
          variant="outlined"
          size="small"
          fullWidth
          select
          label="Review status"
          value={reviewStatus}
          onChange={(e) => {
            setReviewStatus(e.target.value)
          }}
          className={classes.textField}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value={REVIEW_STATUS.reviewed}>Reviewed</MenuItem>
          <MenuItem value={REVIEW_STATUS.unreviewed}>Unreviewed</MenuItem>
        </TextField>
        <Stack
          direction="row"
          spacing={1}
          className={classes.textField}
        >
          <TextField
            name="userType"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            select
            label="User type"
            value={userType}
            onChange={handleChangeUserType}
            style={{ width: '12rem' }}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value={USER_TYPES.admin}>Admin</MenuItem>
            <MenuItem value={USER_TYPES.driver}>Driver</MenuItem>
            <MenuItem value={USER_TYPES.customer}>Customer</MenuItem>
          </TextField>
          {!!userType && (
            <CustomizedAutocomplete
              label="Created by"
              size="small"
              value={createdBy}
              inputValue={valueInputCreatedBy}
              handleOpen={handleOpenSearchCreatedBy}
              handleInputChange={handleCreatedByInputChange}
              handleChange={handleChangeSearchCreatedBy}
              options={createdByOptions}
              loading={adminNameLoading || driverLoading || customerLoading}
              className={classes.autocomplete}
            />
          )}
        </Stack>
        <FormControl
          component="fieldset"
        >
          <FormLabel component="legend">
            <Typography variant="h4">
              Channels
            </Typography>
          </FormLabel>
          <FormGroup row>
            {channelOptions.length > 0 && (channelOptions.map((channel) => {
              return (
                <FormControlLabel
                  key={channel.key}
                  classes={{
                    root: classes.formControlLabel,
                  }}
                  control={(
                    <Checkbox
                      checked={channel.isChecked}
                      onChange={handleChangeCheckbox}
                      name={channel.label}
                    />
                  )}
                  label={channel.label}
                />
              )
            }))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          color="secondary"
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          color="secondary"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(Filter)
