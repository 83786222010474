import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'

import { useStores, useDebounce } from 'admin/hooks'
import TextField from 'admin/components/textfield'

import ReportCustomer from './datagrid'

import styles from '../styles'

const useStyles = makeStyles(styles)

const Filter = () => {
  const classes = useStyles()
  const { reportStore } = useStores()

  const [name, setName] = useState('')

  const debounceCustomerName = useDebounce(name, 300)

  useEffect(() => {
    reportStore.updateQuery('name', debounceCustomerName.trim(), true)
  }, [debounceCustomerName])

  return (
    <div className={classes.upperSection}>
      <TextField
        name="phoneNumber"
        type="text"
        variant="outlined"
        size="small"
        fullWidth
        className={classes.field}
        label="Search by Customer Name"
        onChange={(e) => {
          setName(e.target.value)
        }}
      />
    </div>
  )
}

const Listing = () => {
  return (
    <>
      <Filter />
      <ReportCustomer />
    </>
  )
}

export default observer(Listing)
