import React, { useState, useCallback } from 'react'
import { observer } from 'mobx-react'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'

import Page from 'admin/components/page'
import Listing from './listing'
import Analytics from './analytics'

import styles from './styles'

const useStyles = makeStyles(styles)

const Report = () => {
  const classes = useStyles()

  const [activeTab, setActiveTab] = useState(0)

  const handleChangeTab = useCallback((event, value) => {
    setActiveTab(value)
  }, [])

  return (
    <Page>
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          textColor="secondary"
          indicatorColor="secondary"
        >
          {['Customers',
            'Analytics',
          ].map((day) => (
            <Tab
              key={day}
              label={day}
              classes={{
                root: classes.tab,
              }}
            />
          ))}
        </Tabs>
      </Box>
      {activeTab === 0 && <Listing />}
      {activeTab === 1 && <Analytics />}
    </Page>
  )
}

export default observer(Report)
