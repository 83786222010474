import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import {
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Switch,
  Typography,
} from '@mui/material'

import { useStores, useApiRequest } from 'admin/hooks'
import Link from 'admin/components/link'
import * as srv from 'admin/services'

import Protected from 'admin/components/protected'
import Map from 'admin/components/map'

import styles from './styles'

const useStyles = makeStyles(styles)

const DriverSlot = ({
  slot,
  driverSlots,
  setDriverSlots,
  setSelectedServiceArea,
  selectedServiceArea,
}) => {
  const classes = useStyles()

  const updateDriverSlot = (key, value) => {
    const current = driverSlots[slot.id]
    const newDriverSlots = {
      ...driverSlots,
      [slot.id]: {
        ...current,
        [key]: value,
      },
    }
    setDriverSlots(newDriverSlots)
  }

  return (
    <>
      <TableRow
        classes={{
          root: clsx(classes.tableRowRoot, {
            [classes.override]:
              !driverSlots[slot.id].useDefault && driverSlots[slot.id].day !== 'default',
          }),
        }}
      >
        <TableCell
          component="th"
          scope="row"
        >
          {`${slot?.start} - ${slot?.end}`}
        </TableCell>
        <TableCell align="left">
          {driverSlots[slot.id] && driverSlots[slot.id].label
            ? driverSlots[slot.id].label
            : 'No Label'}
        </TableCell>
        <TableCell align="left">
          {driverSlots[slot.id] && driverSlots[slot.id].serviceArea.map((sa) => sa).join(', ')}
        </TableCell>
        <TableCell align="right">
          <Button
            color="secondary"
            variant="outlined"
            size="small"
            startIcon={<Icon>map</Icon>}
            className={classes.button}
            onClick={() => {
              updateDriverSlot('openDialog', true)
              setSelectedServiceArea(driverSlots[slot.id] ? driverSlots[slot.id].serviceArea : [])
            }}
          >
            Set on map
          </Button>
        </TableCell>
        <TableCell align="right">
          <Switch
            color="primary"
            size="small"
            checked={driverSlots[slot.id] && driverSlots[slot.id].isActive}
            onChange={(event) => {
              updateDriverSlot('isActive', event.target.checked)
            }}
          />
        </TableCell>
      </TableRow>
      <Map
        open={driverSlots[slot.id] && driverSlots[slot.id].openDialog}
        onClose={() => {
          updateDriverSlot('openDialog', false)
        }}
        serviceArea={driverSlots[slot.id] && driverSlots[slot.id].serviceArea}
        setServiceArea={setSelectedServiceArea}
        onSave={() => {
          const current = driverSlots[slot.id]
          const newDriverSlots = {
            ...driverSlots,
            [slot.id]: {
              ...current,
              serviceArea: selectedServiceArea,
              openDialog: false,
            },
          }
          setDriverSlots(newDriverSlots)
        }}
        label={driverSlots[slot.id] && driverSlots[slot.id].label}
        setLabel={(event) => {
          updateDriverSlot('label', event.target.value)
        }}
      />
    </>
  )
}

const Default = observer(({ day }) => {
  const classes = useStyles()
  const { infoSectionStore, driverStore, notificationStore, slotStore } = useStores()

  const { selected: driver } = infoSectionStore

  const [driverData, setDriverdata] = useState(null)
  const [driverSlots, setDriverSlots] = useState(null)
  const [selectedServiceArea, setSelectedServiceArea] = useState([])
  const [defaultDriverSlots, setDefaultDriverSlots] = useState(null)

  const { request: fetchDriverById } = useApiRequest(srv.fetchDriverById, { blocking: false })

  const { request: updateDriverSlots } = useApiRequest(srv.updateDriverSlots, { blocking: true })

  // NOTE: dont use useCallback here. will trigger infinite driver fetch
  const save = async (event) => {
    event.preventDefault()
    if (!driver) return
    await updateDriverSlots(driver.id, driverSlots, day)
    notificationStore.setSuccess('Save slots success')

    await driverStore.fetch({ day })
    driverStore.select(driver.id) // reselect after fetch
    infoSectionStore.replace(driverStore.selected)

    const responseDriverData = await fetchDriverById(driver.id, day)
    setDriverdata(responseDriverData?.data)

    if (day === 'default') {
      const responseDefaultDriverSlots = await fetchDriverById(driver.id, 'default')
      setDefaultDriverSlots(responseDefaultDriverSlots?.data)
    }
  }

  useEffect(() => {
    if (!driver) return
    const init = async () => {
      // fetch driver data by id and day
      const responseDriverData = await fetchDriverById(driver.id, day)
      setDriverdata(responseDriverData?.data)

      // fetch default driver slots from server
      const responseDefaultDriverSlots = await fetchDriverById(driver.id, 'default')
      setDefaultDriverSlots(responseDefaultDriverSlots?.data)
    }
    init()
  }, [driver])

  useEffect(() => {
    if (!slotStore.list || slotStore.list.length === 0) return
    // Init driver slots
    const temp = slotStore.list.reduce((obj, currentSlot) => {
      obj[currentSlot.id] = {
        serviceArea: [],
        isActive: false,
        openDialog: false,
        useDefault: true,
        label: '',
        day,
      }
      if (
        driver &&
        driver.slots &&
        defaultDriverSlots &&
        defaultDriverSlots.slots &&
        defaultDriverSlots.slots.length > 0
      ) {
        const data = driverData.slots.find((slot) => slot.id === currentSlot.id)

        const defaultData = defaultDriverSlots.slots.find(
          (defaultSlot) => defaultSlot.id === currentSlot.id,
        )

        if (data && data.driverSlot && defaultData && defaultData.driverSlot) {
          const driverSlotData = data.driverSlot
          const defaultDriverSlotData = defaultData.driverSlot

          const driverSlotDataLength = driverSlotData.serviceArea.length
          const defaultDriverSlotDataLength = defaultDriverSlotData.serviceArea.length

          const isUsingDefaultSlots = () => {
            if (
              day !== 'default' &&
              driverSlotData.isActive === defaultDriverSlotData.isActive &&
              driverSlotDataLength === defaultDriverSlotDataLength
            ) {
              const isUsingDefaultDriverSlotData = defaultDriverSlotData.serviceArea.map((area) =>
                driverSlotData.serviceArea.includes(area),
              )
              if (!isUsingDefaultDriverSlotData.includes(false)) return true
              return false
            }
            return false
          }
          obj[currentSlot.id] = {
            serviceArea: driverSlotData.serviceArea
              ? driverSlotData.serviceArea
              : defaultDriverSlotData.serviceArea,
            isActive: driverSlotData ? driverSlotData.isActive : defaultDriverSlotData.isActive,
            label: driverSlotData ? driverSlotData.label : defaultDriverSlotData.label,
            openDialog: false,
            useDefault: isUsingDefaultSlots(),
            day,
          }
        } else {
          if (!defaultData) return obj
          obj[currentSlot.id] = {
            serviceArea: defaultData.driverSlot.serviceArea,
            isActive: defaultData.driverSlot.isActive,
            label: defaultData.driverSlot.label,
            openDialog: false,
            useDefault: true,
            day,
          }
        }
      }
      return obj
    }, {})
    setDriverSlots(temp)
  }, [slotStore.list, driver, driverData, day, defaultDriverSlots])

  const renderNoSlot = () => {
    return (
      <div className={classes.noRecords}>
        <Typography
          variant="h4"
          className={classes.textNoRecord}
        >
          Please setup slots at
        </Typography>
        <Link
          to="/setting"
          className={classes.textNoRecord}
        >
          setting
        </Link>
        <Typography
          variant="h4"
          className={classes.textNoRecord}
        >
          page, first
        </Typography>
      </div>
    )
  }

  const renderSlots = () => {
    return (
      <>
        <TableContainer
          component={Paper}
          className={classes.paper}
        >
          <Table size="small">
            <TableBody>
              {!!driverSlots &&
                slotStore.list.map((slot) => {
                  return (
                    <DriverSlot
                      key={slot.id}
                      slot={slot}
                      driverSlots={driverSlots}
                      setDriverSlots={setDriverSlots}
                      setSelectedServiceArea={setSelectedServiceArea}
                      selectedServiceArea={selectedServiceArea}
                    />
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.buttonContainer}>
          <Protected
            level="update"
            category="driver"
          >
            <Button
              variant="contained"
              color="secondary"
              className={classes.detailButton}
              onClick={save}
            >
              Save
            </Button>
          </Protected>
        </div>
      </>
    )
  }

  return slotStore.list.length === 0 ? renderNoSlot() : renderSlots()
})

export default Default
