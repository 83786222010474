export default () => ({
  card: {
    width: '100%',
    padding: '1.5rem',
    borderRadius: '8px',
  },
  cardContent: {
    position: 'relative',
    height: '300px',
    width: '100%',
    '&.MuiCardContent-root': {
      padding: '0',
    },
  },
  subTitle: {
    marginBottom: '1rem',
  },
})
